.header-class{
  font-weight: 500;
  text-align: center;
  border-top: 1px solid #d0d0d0;
  //border-right: 1px solid #d0d0d0;
  //border-left: 1px solid #d0d0d0;
  background-color: rgba(255, 255, 255, 0.47);
  color:black;
}
.selection-input-4{
  margin-top:10px !important;
}

.table_action{
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  width: 50% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.toolbar_table{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 40px;
  width: 100%;
}

.filter_section{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 40px;
  width: 35%;
}

.add_btn{
  margin-left: 10px !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  padding: 2px !important;
}

.svg-icon-password{
  color:#009ef7;
  width:18px;
}
.svg-icon-edit{
  color:#009ef7;
}
.svg-icon-del{
  color:#f1416c;
}
