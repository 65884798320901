
select, input{
  border: 1px solid #cecaca;
}

.borderRed2{
  border:1px solid red;
  border-radius:5px;
  margin-bottom: 5px;
}
.borderRed{
  font-size:12px;
  border-radius:5px;
  height:40px;
  border:1px solid red;
}

.modal_close_btn{
  display: flex;
  justify-content: flex-end;
}

.form_container{
  padding-left:15px;
  padding-right: 15px;
}

.new_password_content{
  margin-left:auto;
  margin-right:auto;
  border: 2px solid gray;
  border-radius:8px;
  width: 40%;
  padding: 5px;
  margin-top: 10px;
}

.img_field_border{
  border: 1px solid #cecaca;
  border-radius: 5px;
  height:40px;
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 5px;
}

.img_field_label{
  padding: 8px;
  font-size:12px;
  font-weight:normal;
  border: 1px solid #cecaca;
  border-radius:5px;
  height:40px;
}

.file_name_text{
  margin-top: 7px;
  margin-right:5px;
}

.token_field{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.custom-arrow{
  font-size: 19px;
  color: black;
  font-weight: bold;
}

//for custom arrow in multiselect input
.rmsc .dropdown-heading{
  padding-right:5px;
}

.auto_token_layout{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 90%;
}

.auto_token_label{
  margin-top: 7px;
  font-size: 12px;
}



//modal for json view compare
.modal-90w{
  width: 80% !important;
  height: 80% !important;
  margin-left:auto;
  margin-right:auto;
  margin-top:20px;
  border-radius:10px !important;
}
.scrollable_content_modal{
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
