.header {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  &__wrapper{
   & h5{
     flex: 1;
   }
  }

  &__blue{
    color: #009ef7;
  }

  &__page {
    margin-left: auto;
  }
}

.filter_links {
  display: grid;
  grid-template-columns: repeat(3, 33%);

  & p {
    max-width: 30%;
    cursor: pointer;

    &:hover {
      color: #009ef7;
    }
  }
}


.additional_data {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  & p {
    max-width: 50%;
  }
}

.comment {
  grid-column: span 4;
  display: flex;
  align-items: center;
  gap: 10px;
}
