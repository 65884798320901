.wrapper {
  display: flex;
  //justify-content: space-between;
  gap: 10px;
  padding: 10px;
  flex-direction: row;
  border-radius: 0;
  z-index: 2;

  &__no_header{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

  }
}

.pagination{
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}
