.table_wrapper {
  & table {
    display: block;
    overflow-y: auto;
    max-height: 60vh;
    width: 100%; // Ensure the table uses the full container width
    table-layout: fixed; // Enable fixed layout for column widths

    @media only screen and (max-width: 1550px) {
      max-height: 60vh;
    }

    & thead {
      position: sticky;
      top: 0;
      transform: translateY(-1px);
      background-color: white;
      z-index: 1;

      & tr > th:first-child {
        padding-left: 10px; // Extra padding for visual spacing
        width: 50px; // Fixed width for the checkbox column
        text-align: center; // Center-align the checkbox
      }

      & th {
        padding: 12px;
        font-size: 14px;
        text-align: center;
      }
    }
    & input[type='checkbox'] {
      width: 17px; // Increase checkbox width
      height: 17px; // Increase checkbox height
      cursor: pointer; // Add pointer cursor for better UX
      margin: 2px; // Add space around the checkbox
      border: 1px solid #ccc; // Add a subtle border
      border-radius: 6px; // Round the corners
      background-color: #fff; // Ensure a clean background
      transition: all 0.2s ease; // Add smooth transitions
    }

    & tbody {
      & tr {
        &:hover {
          background-color: #f7f7f7;
        }

        & td:first-child {
          width: 50px; // Match the width of the header
          text-align: center; // Center-align the checkbox
          padding: 8px; // Ensure consistent padding
        }

        & td {
          padding: 10px;
          font-size: 12px;
          text-align: center;
          white-space: nowrap; // Prevent text wrapping if needed
        }
      }
    }
  }
}
