
.red_border{
  font-size:12px;
  border-radius:5px;
  height:40px;
  border:1px solid red;
}

.modal_close_btn{
  display: flex;
  justify-content: flex-end;
  margin-bottom:5px;
}

.form_container{
  padding-left:15px;
  padding-right: 15px;
}

.new_password_content{
  margin-left:auto;
  margin-right:auto;
  border: 2px solid gray;
  border-radius:8px;
  width: 40%;
  padding: 5px;
  margin-top: 10px;
}


