.modal_block_password_modal{
  margin-left:auto;
  margin-right:auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content:center;
}

.modal_block_general{
  margin-left:auto;
  margin-right:auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content:center;
}

.mandatory_star{
  color:red;
}

.label_font_size{
  font-size:12px;
}

.confirm_btn{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.flex_row_between{
  display: flex;
  justify-content:space-between;
  flex-direction: row;
}
.title_close_space{
  padding:5px;
  text-align: center;
  margin-left:10px;
  margin-top: 5px
}

.margin_b_5{
  margin-bottom: 5px;
}

.classic_input{
  font-size:12px;
  border-radius:4px;
  height:40px;
  margin-bottom: 5px;
}

.borderRed2{
  border:1px solid #f1416c;
  border-radius:5px;
  margin-bottom: 5px;
}
.borderRed{
  font-size:12px;
  border-radius:5px;
  height:40px;
  border:1px solid #f1416c;
}
