.comm_table,
.comm_job_table,
.comm_details,
.segment_page,
.items_table {
  .react-bootstrap-table {
    overflow-x: auto !important;
  }

  .react-bootstrap-table table {
    table-layout: auto;

    & thead th,
    & tbody td {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  Button {
    font-size: 12px !important;
    height: 40px;
  }
}

.comm_details {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.comm_block_top_part {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 8px 8px 0 8px;
}

.comm_block_title {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 8px 8px 0 8px;

  div {
    width: 280px;
  }
}

.comm_block_title_2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 8px;
  padding-right: 8px;

  div {
    width: 280px;
  }
}

.comm_block_title_3 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 8px;
  padding-right: 8px;

  div {
    width: 280px;
  }
}

.comm_block_title_4 {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 8px;
  padding-right: 8px;

  div {
    width: 120px;
  }

  .output_table_container {
    //margin-right: 15px;
    width: 315px;
  }

  .comment_section {
    border-radius: 8px;
    border: 1px solid #cecaca;
  }

  .comment_container {
    margin-left: 200px;
  }
}

.three_dots {
  cursor: pointer;
}

.copy_btn {
  cursor: pointer;
  margin-left: 10px;
}

.comment_community {
  border: 1px solid #cecaca;
  border-radius: 8px;
}

.job_table {
  max-height: 600px;
  max-width: 100%;

  .dropdown-menu {
    position: absolute;
    top: -61px;
  }
}

.tt_sm {
  z-index: 99999 !important;
}

.comm_job_table {
  tbody {
    overflow-y: auto !important;
    width: 100%;
    height: 100%;
  }

  tr:hover {
    cursor: pointer;
  }
}

.see_more_dots {
  cursor: pointer;
  font-size: 25px;
  color: #009ef7;
}

.comm_table {
  tr:hover {
    cursor: pointer;
  }

  td {
    height: 50px !important;
  }
}

.filter_toolbar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 40px;
  gap: 20px;
}

.toolbar_table_annotation {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;

  transform: scale(0.9);
  transform-origin: top left;
  width: 171.11%;
  overflow-y: auto;

  &__perPage {
    margin-left: auto;
    width: max-content;
  }

  select {
    height: 41px;
  }
}

.comm_table_main {
  transform: scale(0.75);
  transform-origin: top left;
  width: 130.86%;

  height: 100vh;
  overflow-y: auto;
}

.toolbar_table_community_main {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;

  transform: scale(0.85);
  transform-origin: top left;
  width: 100%;

  height: 100vh;
  overflow-y: auto;

  &__perPage {
    margin-left: auto;
    width: max-content;
  }

  select {
    height: 41px;
  }
}

.seg_img_btns_modal {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 60%;
  padding: 15px;
}

.bottom_bar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.segment_page {
  .comment_section {
    textarea {
      border: 1px solid #cecaca;
      border-radius: 8px;
      padding: 5px;
    }
  }

  .seg_images {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: scroll;
    max-height: 670px;
    max-width: 100%;
  }

  .seg_filter,
  .seg_det_row_1,
  .seg_det_row_3,
  .seg_img_btns {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
  }

  .seg_filter_none {
    display: inline-block;
    padding-right: 19px;
  }

  .seg_det_row_5 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 60%;
  }

  .seg_details,
  .seg_images,
  .seg_filter {
    padding: 25px;
  }

  .seg_det_row_1 {
    margin-bottom: 8px;
  }

  .seg_det_row_1 div {
    display: flex;
    flex-direction: row;

    input {
      width: 50px;
    }

    Label {
      margin-top: 10px;
      margin-right: 5px;
    }
  }

  .seg_filter_part_1 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 1200px;
    height: 45px;

    select {
      height: 41px;
    }

    #reset_btn:hover:not(.btn-active) {
      background-color: #23c2d1 !important;
    }

    #reset_btn {
      background-color: #23c2d1 !important;
    }
  }

  .seg_filter_part_2 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .page-item.active .page-link {
      background-color: #009ef7 !important;
    }
  }

  .seg_det_row_2 div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 8px 8px 0 8px;
    height: 38px;
  }

  .seg_det_row_3 {
    .seg_det_row_3_info_single {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 15px;
    }

    .seg_det_row_3_info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    input,
    select {
      margin-left: 10px;
    }
  }

  .seg_img_btns {
    margin-left: 35px;
    margin-top: 10px;
    width: 85%;

    .seg_btns {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 370px;

      .details_btn {
        margin-left: 40px;
      }
    }
  }

  .seg_img_exemple {
    margin-bottom: 30px;
    margin-left: 3px;

    img {
      cursor: pointer;
    }
  }

  .svg-icon-android {
    color: green;
    margin-bottom: 5px;
  }

  .svg-icon-edit-new {
    color: gray;
  }

  .svg-icon-edit-selected {
    color: #009ef7;
  }

  .status_chip {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
  }
}

.switch {
  margin-top: 8px;
}

.icon_selected {
  color: #009ef7 !important;
}

.svg-icon-edit-unselected {
  color: gray;
}

.no_results {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: white;
  height: 50px;
}

.filter_link_sign {
  position: absolute;
  right: 350px;
  margin-top: 10px;
}

.seg_long_copy {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
  user-select: none;
}

//animation

.detail-exit {
  opacity: 1;
}

.detail-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
  animation: heightChange 300ms ease-in-out both;
}

.detail-exit-done {
  opacity: 0;
  max-height: 0;
}

.detail-enter {
  opacity: 0;
  transform: scale(0.9);
}

.detail-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
  animation: heightChange 300ms reverse ease-in-out both;
}

@keyframes heightChange {
  0% {
    max-height: 1000px;
  }
  25% {
    max-height: 750px;
  }
  50% {
    max-height: 500px;
  }
  75% {
    max-height: 250px;
  }
  90% {
    max-height: 100px;
  }
  100% {
    max-height: 0;
  }
}

// Define your color palette
$blue-primary: #005b96; // Primary blue
$blue-secondary: #007bff; // Secondary blue, slightly lighter
$blue-light: #e7f1f8; // Light blue for backgrounds
$red-delete: #ff5b5b; // Red color for delete actions

/* General button styling */
.button {
  background-color: #005b96; /* Primary blue */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #007bff; /* Secondary blue, slightly lighter */
}

/* Specific styles for 'confirm-danger' class to maintain red color on focus */
.button.confirm-danger {
  background-color: #ff5b5b; /* Red color for delete actions */
}

.button.confirm-danger:hover,
.button.confirm-danger:focus {
  background-color: darken(#ff5b5b, 10%); /* Darken the red on hover/focus */
}

/* Ensure specificity to override Bootstrap's default styles if necessary */
.btn.confirm-danger:focus,
.btn.confirm-danger:active {
  background-color: darken(#ff5b5b, 10%) !important; /* Ensure the focus color remains */
  border-color: darken(#ff5b5b, 10%) !important;
}

/* Increase specificity by targeting directly within a specific ID or class that wraps your component */
#yourComponentId .button.confirm-danger,
#yourComponentId .btn.confirm-danger {
  background-color: #ff5b5b; /* Red color for delete actions */
  border-color: #ff5b5b; /* Match border color to background */
}

#yourComponentId .button.confirm-danger:hover,
#yourComponentId .button.confirm-danger:focus,
#yourComponentId .btn.confirm-danger:hover,
#yourComponentId .btn.confirm-danger:focus {
  background-color: darken(#ff5b5b, 10%); /* Darken for hover and focus */
  border-color: darken(#ff5b5b, 10%); /* Ensure border color matches */
}

/* Further specificity for Bootstrap buttons if necessary */
#yourComponentId .btn.confirm-danger:focus,
#yourComponentId .btn.confirm-danger:active {
  background-color: darken(#ff5b5b, 10%) !important;
  border-color: darken(#ff5b5b, 10%) !important;
}

.dialog {
  .MuiDialog-paper {
    border-radius: 8px;
    padding: 20px;
    width: 500px; // Increased width for better visibility
    max-width: 80%; // Ensures responsiveness on smaller screens
  }

  .MuiDialogTitle-root {
    color: $blue-primary;
    font-size: 20px; // Slightly larger for emphasis
  }

  .MuiDialogContentText-root {
    color: darken($blue-primary, 10%);
    font-size: 16px; // Clear readability
  }

  .MuiButton-root {
    margin: 8px;
    padding: 8px 20px; // Slightly larger padding for a better touch area
    font-weight: bold; // Make the text bold to stand out
  }
}
