.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.label {
  color: #333;
  font-weight: normal;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0%, -100%)
}

.icon {
  font-size: 12px;
  transform: translate(50%, 30%);

  &__unchecked{
    transform: translate(-67%, 30%);

  }
}
