.container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

//.current_tag{
//  color: #009ef7;
//  padding: 5px;
//display: flex;
//  align-items: center;
//  justify-content: center;
//
//  & span:last-of-type{
//    margin-left: auto;
//    margin-right: 5px;
//    cursor: pointer;
//  }
//}

.images {
  display: grid;
  padding: 2rem 5px 0 5px;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-width: 1700px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  &__tagging {
    padding: 1rem 0.5rem;
    display: grid;
    grid-template-columns: repeat(8, max-content);
    gap: 5px;
    justify-items: center;
  }

  &__image_container{
    img{
      cursor: pointer;
    }
    &--zoom{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img{
        cursor: default;
      }

      .images__tagging {
        z-index: 1;
        opacity: 1;
        max-width: 1000px;
        transform: scaleY(1);
      }
    }

  }
}

.page{
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%,100%);
}

.no_results{
  grid-column: 1 /-1;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.mag_glass{
  display: block;
  transform: translateY(7px);
}
