.menu_context {
  color: black;
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 8px;
  width: 170px;
  height: auto;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 2;
  z-index: 5;
  transition: opacity 0.5s linear;
  position: absolute;
  left: -28%;
  top: -4%;
  text-align: left;

  p {
    cursor: pointer;
    a:hover {
      color: #009ef7;
    }
  }

  .config_link {
    color: black;
    font-size: 14px;
    .config_link:hover {
      color: #009ef7;
    }
  }

  p:hover {
    color: #009ef7;
  }
}

.header-class {
  font-weight: 500;
  text-align: center;
  border-top: 1px solid #d0d0d0;
  //border-right: 1px solid #d0d0d0;
  //border-left: 1px solid #d0d0d0;
  background-color: rgba(255, 255, 255, 0.47);
  color: black;
}

.banner_alert {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: absolute;
}

.banner_alert_error {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: 1px solid red;
  color: red;
  position: absolute;
}

.table_action {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.alert_message {
  position: relative;
  left: 35%;
}

.toolbar_table {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 40px;
  width: 100%;
}

.flex_row_space {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.flex-row-space {
  div {
    margin-right: 5px;
  }
}

.filter_section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 40px;
  width: 35%;
}

.filter_select {
  height: 41px;
  border-radius: 7px;
}

.filter_box {
  height: 50px;
  margin-right: 10px;
}

.add_btn {
  margin-left: 10px !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  padding: 2px !important;
}

.btn--second {
  margin-left: 10px;
}

.svg-icon-delete {
  color: #f1416c;
}
.svg-icon-edit {
  color: #009ef7;
}
.svg-icon-edit-star {
  color: #51bcf8;
}
.svg-icon-actions {
  color: #009ef7;
}
.svg-icon-context {
  color: #009ef7;
  margin-bottom: 5px;
  margin-right: 5px;
}

.imageFormatter {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &__p {
    margin-bottom: 0;
  }

  &__image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  &__name {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
  }
}
