.tag {
  color: #009ef7;
  font-weight: bold;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.3s ease;

  &:hover{
    scale: 1.05;
    opacity: 0.8;
  }

  &__selected{
    opacity: 1;

    &:hover{
      opacity: 1;
    }
  }
}
