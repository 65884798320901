.banner_alert{
  width:30%;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  position:absolute;
}

.banner_alert_error{
  width:30%;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  border: 1px solid red;
  color:red;
  position:absolute;
}

.alert_message{
  position: relative;
  left: 35%;
}
