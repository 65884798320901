.container {
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr) max-content;
  gap: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tagging_summary {
  grid-column: 1 / -2;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed gray;

  & > *:first-child{
    grid-column: span 1;
  }

  & > *:nth-child(2){
    grid-column: span 7;
  }

  &__button {
    justify-self: end;
    grid-column: 5/-1;
    grid-row: 1/2;

  }

  &__link {
    justify-self: end;
    grid-column: 5/-1;
    grid-row: 5/6;
    max-height: 42px;

    & a {
      &:link, &:active, &:hover, &:visited {
        color: white;
      }
    }
  }

  &__comment {
    justify-self: start;
    align-self: end;
    grid-column: 1/5;
    grid-row: 5/6;
    width: 100%;

    & textarea{
      height: 30px;

      &::placeholder{
        font-weight: normal;
      }
    }
  }
}

.data_container{
  grid-column: 1 / -2;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 0.5rem;
}
