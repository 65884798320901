.tab-content{
  .alert_warning{
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    border:none;
    background: rgba(106, 158, 193, 0.16);
    border-radius:8px;
    padding:10px;
    margin-bottom: 10px;
    div{
      display:flex;
      justify-content: flex-start;
      flex-direction:row;
    }
  }

}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit-done {
  opacity: 0;
}

.ignore_btn{
  font-size:10px;
  width:20%;
  margin-left:47%;
  padding:3px !important;
}
.ca_btn{
  font-size:11px;
  width:100%;
  padding:5px !important;
  margin-top:5px;
}
.topborder_notification{
  display: flex;
  justify-content:space-between;
  flex-direction: row;
  width:100%;
  padding:15px;
  height:50px;
  //border:1px solid black;
}
.warning_title_block{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top:5px;
}

.svg-icon-warning{
  margin-bottom:5px;
}
