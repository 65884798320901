.container {
  .paginationContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    background: #f3f6f9;
    border-top: 1px solid #e4e6ef;
    flex-shrink: 0;
    .muiPagination,
    .tablePagination {
      display: flex;
      align-items: center;
      .MuiTablePagination-displayedRows {
        display: none;
      }
    }

    .MuiTablePagination-displayedRows {
      display: none;
    }

    .actions {
      display: none;
    }
    .selectLabel {
      display: none;
    }
    .displayedRows {
      display: none;
    }

    .tablePagination {
      font-size: 14px;
      flex-grow: 1;
      justify-content: flex-end;

      .rowsPerPageOptions {
        display: none;
      }

      .MuiTablePagination-toolbar {
        display: flex;
        align-items: center;
        padding: 0;
        min-height: auto;
      }

      .MuiButtonBase-root.MuiIconButton-root {
        padding: 8px;
      }

      .MuiTablePagination-selectLabel,
      .MuiTablePagination-input,
      .MuiTablePagination-select,
      .MuiTablePagination-actions {
        font-size: 14px;
      }
    }

    .muiPagination {
      margin-left: auto;
    }

    // Define your color palette
    $blue-primary: #005b96; // Primary blue
    $blue-secondary: #007bff; // Secondary blue, slightly lighter
    $blue-light: #e7f1f8; // Light blue for backgrounds
    $red-delete: #ff5b5b; // Red color for delete actions

    /* General button styling */
    .button {
      background-color: #005b96; /* Primary blue */
      color: white;
      border: none;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .button:hover {
      background-color: #007bff; /* Secondary blue, slightly lighter */
    }

    /* Specific styles for 'confirm-danger' class to maintain red color on focus */
    .button.confirm-danger {
      background-color: #ff5b5b; /* Red color for delete actions */
    }

    .button.confirm-danger:hover,
    .button.confirm-danger:focus {
      background-color: darken(#ff5b5b, 10%); /* Darken the red on hover/focus */
    }

    /* Ensure specificity to override Bootstrap's default styles if necessary */
    .btn.confirm-danger:focus,
    .btn.confirm-danger:active {
      background-color: darken(#ff5b5b, 10%) !important; /* Ensure the focus color remains */
      border-color: darken(#ff5b5b, 10%) !important;
    }

    /* Increase specificity by targeting directly within a specific ID or class that wraps your component */
    #yourComponentId .button.confirm-danger,
    #yourComponentId .btn.confirm-danger {
      background-color: #ff5b5b; /* Red color for delete actions */
      border-color: #ff5b5b; /* Match border color to background */
    }

    #yourComponentId .button.confirm-danger:hover,
    #yourComponentId .button.confirm-danger:focus,
    #yourComponentId .btn.confirm-danger:hover,
    #yourComponentId .btn.confirm-danger:focus {
      background-color: darken(#ff5b5b, 10%); /* Darken for hover and focus */
      border-color: darken(#ff5b5b, 10%); /* Ensure border color matches */
    }

    /* Further specificity for Bootstrap buttons if necessary */
    #yourComponentId .btn.confirm-danger:focus,
    #yourComponentId .btn.confirm-danger:active {
      background-color: darken(#ff5b5b, 10%) !important;
      border-color: darken(#ff5b5b, 10%) !important;
    }

    .dialog {
      .MuiDialog-paper {
        border-radius: 8px;
        padding: 20px;
        width: 500px; // Increased width for better visibility
        max-width: 80%; // Ensures responsiveness on smaller screens
      }

      .MuiDialogTitle-root {
        color: $blue-primary;
        font-size: 20px; // Slightly larger for emphasis
      }

      .MuiDialogContentText-root {
        color: darken($blue-primary, 10%);
        font-size: 16px; // Clear readability
      }

      .MuiButton-root {
        margin: 8px;
        padding: 8px 20px; // Slightly larger padding for a better touch area
        font-weight: bold; // Make the text bold to stand out
      }
    }
  }
}
