.img_details {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  gap: 1rem;
  padding: 0 10px 5px 10px;
  justify-content: space-between;

  div {
    p:hover {
      color: #009ef7;
      cursor: pointer;
    }
  }
}

.tag_border {
  border: 1px solid black;
  padding: 3px;
  border-radius: 5px;
  margin-left: 20px;
}

.img_details_num {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding: 0 10px;

  div {
    margin-right: 10px;
    width: 100px;
  }
}

.summary_table {
  padding: 10px;

  table {
    border: 1px solid #cecaca;
  }

  thead th, tbody td {
    font-size: 14px;
    border-top: 1px solid #cecaca;
    padding: 10px;
  }

  .row_table, thead th {
    text-align: center;
  }

  thead th {
    width: 450px;
    font-weight: 600;
  }

  tbody td {
    height: 50px;
  }
}

.segment_filter_window {
  cursor: default;

  .filter_card {
    display: flex;
    width: 850px;
    box-shadow: 1px 5px 15px -5px rgba(0, 0, 0, 0.43);
    position: absolute;
    z-index: 5;
    left: 0;
    bottom: 0;
    transform: translateY(110%);


    Button {
      height: 25px !important;
    }

    .plus_button {
      width: 5%;
      color: #009ef7 !important;

      & > * {
        color: #009ef7 !important;

      }
    }

    .refresh_button, .del_button {
      cursor: pointer;
    }

    .active_delete {
      color: #f1416c !important;
    }

    .active_paste {
      color: #009ef7 !important;
    }

    .inactive_plus {
      color: gray !important;
      cursor: not-allowed;
    }

    .active_plus {
      color: #009ef7 !important;
      cursor: pointer;
    }
  }

  &--sort {
    & .filter_card {
      width: 500px;
    }
  }
}

.modal_animation-enter {
  opacity: 0;
}

.modal_animation-enter-active {
  opacity: 1;
  transition: opacity 300ms;
  animation: heightChange 300ms reverse ease-in-out both;
}

.modal_animation-exit {
  opacity: 1;
}

.modal_animation-exit-active {
  opacity: 0;
  transition: opacity 300ms;
  animation: heightChange 300ms ease-in-out both;
}

.modal_animation-exit-done {
  opacity: 0;
  max-height: 0;

}


@keyframes heightChange {
  0% {
    max-height: 1000px;
  }
  25% {
    max-height: 750px;
  }
  50% {
    max-height: 500px;
  }
  75% {
    max-height: 250px;
  }
  90% {
    max-height: 100px;
  }
  100% {
    max-height: 0;
  }
}


