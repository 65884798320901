.icon {
  font-size: 18px;
  cursor: pointer;
  color: #564ab1;

  &:hover,
  &:active {
    color: lighten(#564ab1, 20);
  }
}

.bubble {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 60%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  opacity: 0;
  transform-origin: top right;
  transition: all 150ms;

  & > * {
    display: flex;
    align-items: center;
    gap: 5px;
    width: max-content;
  }

  &::before {
    content: '';
    position: absolute;
    //top: 50%;
    top: 2%;
    right: -10px;
    transform: translate(0, -50%);
    border-left: 10px solid white;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
}

.enter {
  opacity: 0;
  transform: translate(-100%, 0) scale(0.5);
}

.enterDone {
  opacity: 1;
  transform: translate(-110%, 0) scale(1);
}

.exit {
  opacity: 1;
  transform: translate(-110%, 0) scale(1);
}

.exitActive {
  opacity: 0;
  transform: translate(-100%, 0) scale(0.5);
}
